import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CheckCircleFill, HourglassSplit } from 'react-bootstrap-icons';

import {
  listAllTasks,
  getTask,
  deleteTask,
  listSubtasksOfTasks,
  updateTask,
} from '../api/tasks';

import GisContext from '../context/gisContext';

import {
  TaskColStyle,
  TaskColTitleStyle,
  TaskContainerStyle,
  TaskDone,
  TaskRowStyle,
  TaskColFooter,
} from './taskStyle';

const Tasks = ({ taskId, columnName, boardQuery, roleData }) => {
  const [isEnabled, setEnabled] = useState(true);
  const [isDone, setDone] = useState(false);
  const [updatedTask, setUpdatedTask] = useState(null);
  const [taskInfo, setTaskInfo] = useState('');
  const [taskSectionIdSet, setTaskSectionIdSet] = useState(new Set());
  const [hasAssociatedSubtasks, setHasAssociatedSubtasks] = useState(false);

  const { taskTrigger, setTaskTrigger } = React.useContext(GisContext);

  // console.log('TaskTrigger-----------', taskTrigger);

  const queryClient = useQueryClient();

  const { data: subtaskOfTask, refetch: refetchSubtasks } = useQuery(
    ['subtasks', taskId],
    () => listSubtasksOfTasks(taskId),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
    }
  );

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const task = await getTask(taskId);
      if (isMounted) setTaskInfo(task);
    };

    const fetchDataWithTimeout = () => {
      setTimeout(() => {
        fetchData();

        // refetch subtasks when taskTrigger changes

        refetchSubtasks({ force: true }).then(() => {
          setTaskTrigger(false);
        });
      }, 500);
    };

    fetchDataWithTimeout();

    return () => {
      isMounted = false;
      setTaskInfo('');
      setUpdatedTask(null);
      clearTimeout(fetchDataWithTimeout);
    };
  }, [taskId, taskTrigger, refetchSubtasks, setTaskTrigger]);

  useEffect(() => {
    const newTaskSectionIdSet = new Set();
    const newHasAssociatedSubtasks = subtaskOfTask?.some(
      (subtask) => subtask.section_status_id !== 'wf8'
    );
    subtaskOfTask?.forEach((subtask) => {
      newTaskSectionIdSet.add(subtask.section_status_id);
    });
    setTaskSectionIdSet(newTaskSectionIdSet);
    setHasAssociatedSubtasks(newHasAssociatedSubtasks);
  }, [subtaskOfTask]);

  useEffect(() => {
    // console.log('taskSectionIdSet', taskSectionIdSet);
    if (
      taskSectionIdSet.size === 1 &&
      taskSectionIdSet.has('wf8') &&
      !hasAssociatedSubtasks
    ) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [taskSectionIdSet, hasAssociatedSubtasks]);

  const handleDone = async () => {
    try {
      setEnabled(false); // Disable button during update process
      setDone(true);
      const doneTask = await updateTask(taskId, { section_status_id: 'wf9' });
      setUpdatedTask(doneTask);
      queryClient.invalidateQueries(['subtasks', taskId]); // Trigger refetch of subtasks
      queryClient.invalidateQueries(['task', taskId]); // Trigger refetch of the task
      await boardQuery.refetch();
    } catch (error) {
      // console.log('Error updating task:', error);
    } finally {
      setTimeout(() => {
        setEnabled(false); // Enable button after a small delay
        setDone(false); // Reset isDone state after task update
      }, 50);
    }
  };

  useEffect(() => {
    if (hasAssociatedSubtasks) {
      updateTask(taskId, { section_status_id: 'wf1' }).then((updatedTask) => {
        setUpdatedTask(updatedTask);
        queryClient.invalidateQueries(['task', taskId]); // Trigger refetch of the task
        boardQuery.refetch();
      });
    }
  }, [hasAssociatedSubtasks]);

  const isDueDateExpired = moment
    .utc(taskInfo.due_date)
    .isBefore(moment(), 'day');

  // if (
  //   roleData?.Profile.BoardMembers.role === 'reviewer' &&
  //   taskInfo.created_by !== roleData?.Profile.User.username
  // ) {
  //   return null;
  // }

  return (
    <TaskContainerStyle>
      <TaskRowStyle>
        <TaskColTitleStyle>{taskInfo && taskInfo.tile}</TaskColTitleStyle>
        <TaskColTitleStyle priority={taskInfo && taskInfo.priority}>
          {taskInfo && taskInfo.priority}
        </TaskColTitleStyle>
        <TaskColTitleStyle
          priority={taskInfo && taskInfo.task_owner}
          taskType="task_owner"
        >
          {taskInfo && taskInfo.task_owner}
        </TaskColTitleStyle>
      </TaskRowStyle>
      {subtaskOfTask &&
        subtaskOfTask.map((subtask, index) => (
          <TaskRowStyle key={subtask.id}>
            <TaskColStyle>Area {subtask.area}</TaskColStyle>
            <TaskColStyle>V{subtask.version}</TaskColStyle>
            <TaskColStyle
              style={{
                color:
                  subtask.section_status_id === 'wf8' &&
                  taskSectionIdSet.has('wf8')
                    ? 'green'
                    : 'black',
              }}
            >
              {columnName[subtask.section_status_id]}
              {taskSectionIdSet.has('wf8') &&
              subtask.section_status_id === 'wf8' ? (
                <CheckCircleFill
                  style={{ marginLeft: '1.2px', verticalAlign: 'middle' }}
                />
              ) : (
                <HourglassSplit
                  style={{
                    marginLeft: '1.2px',
                    verticalAlign: 'middle',
                    color: 'orange',
                  }}
                />
              )}
            </TaskColStyle>
          </TaskRowStyle>
        ))}
      <TaskRowStyle>
        <TaskColFooter>
          {taskSectionIdSet.size === 1 && taskSectionIdSet.has('wf8') ? (
            <TaskDone
              disabled={isEnabled}
              type="button"
              style={{ backgroundColor: updatedTask ? '#A9A9A9' : '#3CB371' }}
              onClick={handleDone}
            >
              {isDone
                ? 'Submitting...'
                : updatedTask
                ? 'Task Completed'
                : 'Task Done'}
            </TaskDone>
          ) : (
            <TaskDone disabled={isEnabled} type="text">
              Ongoing
            </TaskDone>
          )}
        </TaskColFooter>
        <TaskColFooter>{taskInfo && taskInfo.country_code}</TaskColFooter>
        <TaskColFooter
          style={{
            color: isDueDateExpired ? 'red' : 'green',
          }}
        >
          {taskInfo &&
            moment.utc(taskInfo.due_date).local().format('DD-MM-YYYY')}
        </TaskColFooter>
      </TaskRowStyle>
    </TaskContainerStyle>
  );
};

Tasks.propTypes = {
  taskId: PropTypes.string,
  columnName: PropTypes.oneOfType([PropTypes.object]),
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
  roleData: PropTypes.oneOfType([PropTypes.object]),
};

export default Tasks;
