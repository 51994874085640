import React, { useState, useMemo } from 'react';
import {
  ArrowRightCircleFill,
  ArrowLeftCircleFill,
} from 'react-bootstrap-icons';

import { useQuery } from '@tanstack/react-query';

import { listBoardItems } from '../api/board';
import { useAuthContext } from '../../auth/contexts';
import Card from '../cards';
import CreateTask from '../tasks/createTask';
import { getMe } from '../api/profile';
import AccessControl from '../../../permissions/AccessControl';

import GisContext from '../context/gisContext';

import {
  BoardHeaderCol1,
  BoardHeaderCol2,
  BoardHeaderCol3,
  BoardHeaderRow,
  BoardStyle,
  ColumnName,
  ColumnStyle,
  EachColumnStyle,
  InsightBoard,
  InsightBoardItems,
  ProfileContainer, // Added a new container for the profile component
  RightOverflowArrow,
  LeftOverflowArrow, // Added OverflowArrow component
} from './boardStyle';
import Profile from '../profile';

const Board = () => {
  const [authState] = useAuthContext();
  const [taskTrigger, setTaskTrigger] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const providerValue = useMemo(
    () => ({ taskTrigger, setTaskTrigger }),
    [taskTrigger, setTaskTrigger]
  );

  const handleRightArrowClick = () => {
    const container = document.getElementById('board-container');
    if (container) {
      container.scrollBy({ left: 100, behavior: 'smooth' });
      setShowRightArrow(false);
      setShowLeftArrow(true);
    }
  };

  const handleLeftArrowClick = () => {
    const container = document.getElementById('board-container');
    if (container) {
      container.scrollBy({ left: -100, behavior: 'smooth' });
      setShowRightArrow(true);
      setShowLeftArrow(true);
    }
  };

  const handleBoardScroll = (e) => {
    const { scrollLeft, clientWidth } = e.target;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + clientWidth < e.target.scrollWidth);
  };

  const query = useQuery(['listBoardItems'], listBoardItems, {
    // refetchInterval: 5000,
    // refetchIntervalInBackground: false,
  });

  const userId = authState.user.sub;

  const authQuery = useQuery(['getMe', userId], () => getMe(userId));

  if (query.isLoading || authQuery.isLoading) {
    return (
      <div className="loading">
        <img alt="logo" src="/logo.png" />
      </div>
    );
  }

  if (query.isError || authQuery.isError) {
    return <div>The request did not succeed.</div>;
  }

  const boardColumns = query.data.board_info.section_status;
  const boardContents = query.data.all_contents;

  // console.log('boardContents', boardContents);
  let activeTasks = 0;
  let inProgressSubTasks = 0;
  let totalTasks = 0;
  let taskDone = 0;

  Object.keys(boardColumns).forEach((category) => {
    if (category === 'wf3') {
      inProgressSubTasks = boardContents[category].length;
    } else if (category === 'wf1') {
      totalTasks = boardContents[category].length;
    } else if (category !== 'wf8' && category !== 'wf9') {
      activeTasks += boardContents[category].length;
    } else if (category === 'wf9') {
      taskDone = boardContents[category].length;
    }
  });

  if (query.data && authQuery.data) {
    return (
      <GisContext.Provider value={providerValue}>
        <BoardStyle>
          <BoardHeaderRow>
            <BoardHeaderCol1>Digifarm Workspace</BoardHeaderCol1>

            <AccessControl
              userPermissions={authQuery.data.permissions}
              allowedPermissions={['task/add']}
            >
              <BoardHeaderCol2>
                <InsightBoard>
                  <InsightBoardItems style={{ fontSize: '14px' }}>
                    GIS Board
                    <br /> Stats
                  </InsightBoardItems>
                </InsightBoard>
                <InsightBoard>
                  <InsightBoardItems>{inProgressSubTasks}</InsightBoardItems>
                  <InsightBoardItems>Tiles in Work</InsightBoardItems>
                </InsightBoard>
                <InsightBoard>
                  <InsightBoardItems>{totalTasks}</InsightBoardItems>
                  <InsightBoardItems>Total Tasks</InsightBoardItems>
                </InsightBoard>
                <InsightBoard>
                  <InsightBoardItems>{activeTasks}</InsightBoardItems>
                  <InsightBoardItems>Active Subtasks</InsightBoardItems>
                </InsightBoard>
                <InsightBoard>
                  <InsightBoardItems>{taskDone}</InsightBoardItems>
                  <InsightBoardItems>Completed Tasks</InsightBoardItems>
                </InsightBoard>
                <InsightBoard>
                  <CreateTask boardQuery={query} />
                </InsightBoard>
              </BoardHeaderCol2>
            </AccessControl>

            <BoardHeaderCol3>
              <ProfileContainer>
                <Profile profiledata={authQuery.data} />
              </ProfileContainer>
            </BoardHeaderCol3>
          </BoardHeaderRow>

          <ColumnStyle id="board-container" onScroll={handleBoardScroll}>
            {Object.keys(boardColumns).map((category, index) => {
              const cardsArray = [];

              if (
                authQuery.data.Profile.BoardMembers.role === 'delineator' &&
                ['wf1', 'wf9'].includes(category)
              ) {
                return null;
              }

              boardContents[category].forEach((card) => {
                const cardId = card.id;
                if (!cardsArray.includes(cardId)) {
                  cardsArray.push(cardId);
                }
              });
              return (
                <EachColumnStyle key={category}>
                  <ColumnName>
                    {boardColumns[category]}
                    {authQuery.data.Profile.BoardMembers.role ===
                      'delineator' ||
                    authQuery.data.Profile.BoardMembers.role === 'reviewer'
                      ? ''
                      : `: ${cardsArray.length}`}
                  </ColumnName>

                  <Card
                    key={category}
                    cardArray={cardsArray}
                    index={index}
                    column={boardColumns}
                    status={category}
                    roleData={authQuery.data}
                    boardQuery={query}
                  />
                </EachColumnStyle>
              );
            })}
            {/* Overflow Arrow */}
            <RightOverflowArrow onClick={handleRightArrowClick}>
              <ArrowRightCircleFill size={24} />
            </RightOverflowArrow>
            {showLeftArrow && (
              <LeftOverflowArrow
                onClick={handleLeftArrowClick}
                show={showRightArrow}
              >
                <ArrowLeftCircleFill size={24} />
              </LeftOverflowArrow>
            )}
          </ColumnStyle>
        </BoardStyle>
      </GisContext.Provider>
    );
  }
};

export default Board;
