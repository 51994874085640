import axios from 'axios';
import { get, post, del, put } from '../../../utils/API';
import config from '../../../constants/test_config';

const boardId = 1;
// const config = {
//   headers: {
//     Authorization: `Bearer eyJraWQiOiI2T1VQSzF6MDI2aW56NHo0NVFMRHBwYnRYZmwxM1Q5NVdnd2tqVU9JKzhzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiNDBiYzY3Ni00ZjBkLTRhYTAtYTMyZi05NDczMmFjMmNjYjUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfd0ZlamIxTEVNIiwiY29nbml0bzp1c2VybmFtZSI6ImFkaHlheWFuLnByYWRoYW5AZGlnaWZhcm0uaW8iLCJvcmlnaW5fanRpIjoiZDc5ZTg1YjYtMTAzNy00M2VjLWJlMDUtZmUwOWNjZmNhNGFlIiwiYXVkIjoiNjdsc21haWhiczRtOGpoZGdzMDcydTVuYzEiLCJldmVudF9pZCI6Ijc5MTFiYTZlLWYxNGQtNDVkMy04YTgxLTUwZWYyYzUwNjlmYyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjc2MDMyNDUzLCJleHAiOjE2NzcyMzg3NDUsImlhdCI6MTY3NzIzNTE0NSwianRpIjoiMTMwNjVjMjktMWFjYi00ZWVjLWE3NWQtOWFhOWViZDg2OTE1IiwiZW1haWwiOiJhZGh5YXlhbi5wcmFkaGFuQGRpZ2lmYXJtLmlvIn0.bZgFiAuiCc_Io056g1Di0zlNIWAyFlO-H7m3tubGzGVidfGuaytqKvhlkaRwH4bDEWQmmboMqwI-TLOecaDUfR_l6dkh5Ea7yjKxVQ9XO2aWmhnErKlaK7xFk7JjfvAJ8z98krbNmfhSk-eFXgUtZX39wLRH7sHqVVXLon4zXlReTgwPaZYL0LuEdz2La8b4gTySyLCEKOz7tmd9ZObr4y2aPv89qGz2J1-MDx7nKobW2WtQojS_QDmGdgtevLOnb3-CzkrM1G_8-PW6W4k-MAB01C9b_c-v_w_YtIa4EOaQd-1ElP6TY8fcw8AMP2E1_jkKm2NIm6fN5bTRnrScUA`,
//   },
// };

export const listAllTasks = async () => {
  // gis_board_id
  const response = await get(`/tasks?board_id=${boardId}`);
  // const response = await axios.get(
  //   'https://development.kvasir.digifarm.io/jobs'
  // );
  // console.log('res', response);
  return response;
};

export const getTask = async (taskId) => {
  // gis_board_id
  const response = await get(`/tasks/${taskId}`);
  // const response = await axios.get(
  //   `http://localhost:8000/tasks/${taskId}`,
  //   config
  // );

  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return response;
};

export const createTasks = async (params) => {
  const res = await post(`/tasks?board_id=${boardId}`, params);
  // const res = await axios.post(
  //   `http://localhost:8000/tasks?board_id=${boardId}`,
  //   params,
  //   config
  // );
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return res;
};

export const deleteTask = async (taskId) => {
  const res = await del(`/tasks/${taskId}`);
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return res;
};

export const listSubtasksOfTasks = async (taskId) => {
  const response = await get(`/tasks/${taskId}/subtasks`);
  // const response = await axios.get(
  //   `http://localhost:8000/tasks/${taskId}/subtasks`,
  //   config
  // );
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return response;
};

export const updateTask = async (taskId, params) => {
  const res = await put(`/tasks/${taskId}`, params);

  // const res = await axios.put(
  //   `http://localhost:8000/tasks/${taskId}`,
  //   params,
  //   config
  // );
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return res;
};
