import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';

import PropTypes from 'prop-types';

import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import { boardMembers } from '../../api/board';
import { useAuthContext } from '../../../auth/contexts';

import {
  TaskModalStyle,
  TaskHeaderStyle,
  TaskModalBody,
  TaskModalRow,
  TaskModalCol,
  TaskInput,
  TaskLabel,
  StyledDatePicker,
  StyledSelect,
  TaskNotesArea,
  StyledDropdownButton,
  StyledDropdownItem,
  TaskModalColPriority,
  TaskStyleListFiles,
  TaskCreateButtonStyle,
  TaskModalButtonCol,
  TaskCancelButtonStyle,
} from '../taskStyle';
import { createTasks } from '../../api/tasks';

const INITIALVALUES = {
  tile: '',
  version: 1,
  year: new Date(),
  areas: 1,
  country_code: '',
  file_type: '',
  date_with_indexes: [],
  other_type_filename: '',
  due_date: new Date(),
  task_notes: '',
  client_name: '',
  task_owner: '',
  priority: '',
  download_shp: {},
  created_by: '',
  created_at: new Date(),
};

// let enterKeyCount = 0;

const ChooseMember = (props) => {
  const [toggle, setToggle] = useState('1');

  const people = useQuery(['boardMembers'], () => boardMembers());
  if (people.isLoading) {
    return <div>Loading.</div>;
  }

  const nameRole = people.data;

  const handleClick = (e) => {
    setToggle(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <div>
      <TaskLabel>Task Owner</TaskLabel>
      <StyledSelect value={toggle} onChange={handleClick}>
        <option value="1" disabled>
          Members
        </option>
        {nameRole.map((list) => (
          <option key={list.id} value={list.name} title={list.id}>
            {list.name}
          </option>
        ))}
      </StyledSelect>
    </div>
  );
};

// const validatefileName = (val) => {
//   if (val.length === 0 || val.length !== 3) {
//     return false;
//   }

//   return val.length === 3;
// };

const TaskModal = (props) => {
  const { onClose, show, boardQuery } = props;
  const [isLoading, setLoading] = useState(false);
  const [taskState, setTaskState] = useState(INITIALVALUES);
  const [message, setMessage] = useState([]);
  const [updated, setUpdated] = useState('');
  const [inputError, setInputError] = useState('');
  const [enterKeyCount, setEnterKeyCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [authState, authDispatch] = useAuthContext();

  const basicSchema = Yup.object().shape({
    tile: Yup.string()
      .matches(/^\d{2}[A-Z]{3}$/gi, 'Please use the correct mgrs tile name')
      .required('Required'),
    version: Yup.number().min(1, 'Must be more than 0').required('Required'),
    // year: Yup.number().required('Required'),
    areas: Yup.number().min(1, 'Must be more than 0').required('Required'),
    country_code: Yup.string()
      .matches(/^[A-Z]{2}$/gi, 'Please use two letter country code')
      .required('Required'),
    file_type: Yup.string().required('Please select an option'),
    // date_with_indexes: Yup.array()
    //   .max(3)
    //   .of(Yup.string().required('Option is required')),
    other_type_filename: Yup.string(),
    due_date: Yup.date().required('Date Required'),
    task_notes: Yup.string(),
    client_name: Yup.string().required('Required'),
    year: Yup.date().required('Date Required'),
    // task_owner: Yup.string().required('Required'),
    priority: Yup.string().required('Required'),
    // download_shp: '',
    // created_by: '',
  });

  const handleKeyDown = (event) => {
    if (enterKeyCount < 3) {
      if (
        (event.key === 'Enter' || event.key === ',' || event.key === ' ') &&
        updated.trim() !== '' &&
        !message.includes(updated.trim())
      ) {
        event.preventDefault();
        setMessage([...message, updated.trim()]);
        setUpdated('');
        setEnterKeyCount((prevCount) => prevCount + 1);
      }
    } else {
      // console.log('Max key count reached!');
      // disable further key presses
      event.preventDefault();
      return false;
    }
  };

  const handleValueRemove = (valueToRemove) => {
    setMessage(message.filter((value) => value !== valueToRemove));
    setUpdated('');
    setEnterKeyCount((prevCount) => prevCount - 1);
  };

  const handleChangeFile = (e) => {
    setUpdated(e.target.value);
  };

  useEffect(() => {
    if (!show) {
      // Reset form values when modal is closed
      setTaskState({ ...INITIALVALUES });
      setMessage([]);
      setUpdated('');
      setEnterKeyCount(0);
    }
  }, [show]);

  const {
    values,
    errors,
    dirty,
    isValid,
    touched,
    resetForm,
    trigger,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: INITIALVALUES,
    validationSchema: basicSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);

      setSubmitting(true);
      setLoading(false);
      setIsSubmitting(true);

      const createTask = await createTasks({
        ...values,

        other_type_filename:
          taskState.file_type === 'others' ? message : updated,
        date_with_indexes: taskState.file_type !== 'others' ? message : [],

        year: values.year.getFullYear(),

        task_owner: taskState.task_owner,
        created_by: authState.user.email,
      });

      resetForm({ ...INITIALVALUES });
      onClose();
      await boardQuery.refetch();

      setSubmitting(false);
      setIsSubmitting(false);
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // actions.resetForm();
    },
  });

  const handleBlurMember = () => {
    Yup.string()
      .required('Select an option')
      // .validate(taskState.task_owner)
      .then(() => {
        setInputError('');
      })
      .catch((error) => {
        setInputError(error.message);
      });
    handleBlur(taskState.task_owner);
  };

  const handleCancel = () => {
    resetForm();
    setUpdated('');
    setMessage([]); // Reset the message state
    setEnterKeyCount(0); // Reset the enterKeyCount
    onClose(); // Hide the modal
  };

  // setFieldTouched('date_with_indexes');
  // setFieldError(
  //   'other_type_filename',
  //   !validatefileName(message) ? 'Three file names' : ''
  // );

  return (
    <TaskModalStyle
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <TaskHeaderStyle closeButton>
        <Modal.Title>New task</Modal.Title>
      </TaskHeaderStyle>
      <TaskModalBody>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <TaskModalRow>
              <TaskModalCol>
                <TaskLabel>Tile</TaskLabel>
                <TaskInput
                  type="text"
                  placeholder="MGRS"
                  id="tile"
                  name="tile"
                  onChange={handleChange}
                  value={values.tile}
                  onBlur={handleBlur}
                />
                {touched.tile && errors.tile && (
                  <div className="error">{errors.tile}</div>
                )}
              </TaskModalCol>
              <TaskModalCol>
                <TaskLabel>Version</TaskLabel>
                <TaskInput
                  type="number"
                  min="1"
                  placeholder="version"
                  id="version"
                  onChange={handleChange}
                  value={values.version}
                  onBlur={handleBlur}
                />
                {touched.version && errors.version && (
                  <div className="error">{errors.version}</div>
                )}
              </TaskModalCol>
              <TaskModalCol>
                <TaskLabel>Areas</TaskLabel>

                <TaskInput
                  type="number"
                  min="1"
                  placeholder="areas"
                  id="areas"
                  onChange={handleChange}
                  value={values.areas}
                  onBlur={handleBlur}
                />
                {touched.areas && errors.areas && (
                  <div className="error">{errors.areas}</div>
                )}
              </TaskModalCol>
              <TaskModalCol>
                <TaskLabel>Year</TaskLabel>

                <StyledDatePicker
                  id="year"
                  selected={values.year}
                  onChange={(date) => setFieldValue('year', date)}
                  showYearPicker
                  dateFormat="yyyy"
                  onBlur={handleBlur}
                />
                {touched.year && errors.year && (
                  <div className="error">{errors.year}</div>
                )}
              </TaskModalCol>
            </TaskModalRow>
            <TaskModalRow>
              <TaskModalCol>
                <TaskLabel>File Type</TaskLabel>
                <StyledSelect
                  id="file_type"
                  name="file_type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.file_type}
                >
                  <option value="" disabled>
                    File Type
                  </option>
                  <option value="MS_evi3">DR v2</option>
                  <option value="rgbn_evi3">DR v1</option>
                  <option value="others">Others</option>
                </StyledSelect>
              </TaskModalCol>
              <TaskModalCol>
                {values.file_type === '' ? (
                  ''
                ) : values.file_type === 'others' ? (
                  <div>
                    <TaskLabel>File names</TaskLabel>
                    <TaskInput
                      type="text"
                      id="other_type_filename"
                      placeholder="File name"
                      name="other_type_filename"
                      onChange={handleChangeFile}
                      value={updated}
                    />
                  </div>
                ) : (
                  <div>
                    <TaskLabel>File names</TaskLabel>
                    <TaskInput
                      type="text"
                      id="date_with_indexes"
                      placeholder="three dates"
                      name="date_with_indexes"
                      onKeyDown={handleKeyDown}
                      onChange={handleChangeFile}
                      value={updated}
                    />

                    {/* <MultiValueTextInput /> */}
                  </div>
                )}
                {message.length > 0 && (
                  <TaskStyleListFiles>
                    {message.map((value) => (
                      <li key={value}>
                        {value}{' '}
                        <button
                          type="button"
                          onClick={() => handleValueRemove(value)}
                        >
                          x
                        </button>
                      </li>
                    ))}
                  </TaskStyleListFiles>
                )}
              </TaskModalCol>
              <TaskModalCol>
                <TaskLabel>Due Date</TaskLabel>

                <StyledDatePicker
                  selected={values.due_date}
                  onChange={(date) => setFieldValue('due_date', date)}
                  dateFormat="yyyy/MM/dd"
                />
                {touched.due_date && errors.due_date && (
                  <div className="error">{errors.due_date}</div>
                )}
              </TaskModalCol>
            </TaskModalRow>
            <TaskModalRow>
              <TaskNotesArea
                placeholder="Task Notes...."
                value={values.task_notes}
                onChange={(e) => setFieldValue('task_notes', e.target.value)}
                cols={30}
                rows={6}
              />
            </TaskModalRow>
            <TaskModalRow>
              <TaskModalCol>
                <TaskLabel>Client</TaskLabel>
                <TaskInput
                  type="text"
                  placeholder="Client"
                  id="client_name"
                  onChange={handleChange}
                  value={values.client_name}
                  onBlur={handleBlur}
                />
                {touched.client_name && errors.client_name && (
                  <div className="error">{errors.client_name}</div>
                )}
              </TaskModalCol>
              <TaskModalColPriority>
                <StyledDropdownButton
                  onSelect={(e) => setFieldValue('priority', e)}
                  title="Priority"
                  variant="Light"
                >
                  <StyledDropdownItem eventKey="urgent">
                    Urgent
                  </StyledDropdownItem>
                  {/* <Dropdown.Item>Results</Dropdown.Item> */}
                  <StyledDropdownItem eventKey="high">High</StyledDropdownItem>
                  <StyledDropdownItem eventKey="medium">
                    Medium
                  </StyledDropdownItem>
                  <StyledDropdownItem eventKey="low">Low</StyledDropdownItem>
                </StyledDropdownButton>
                <TaskLabel
                  style={{
                    paddingLeft: '10px',
                    fontSize: '13px',
                    textTransform: 'capitalize',
                  }}
                >
                  {values.priority}
                </TaskLabel>
              </TaskModalColPriority>
              <TaskModalCol>
                <TaskLabel>Country</TaskLabel>
                <TaskInput
                  type="text"
                  placeholder="Country"
                  id="country_code"
                  name="country_code"
                  onChange={handleChange}
                  value={values.country_code}
                  onBlur={handleBlur}
                />
                {touched.country_code && errors.country_code && (
                  <div className="error">{errors.country_code}</div>
                )}
              </TaskModalCol>
              <TaskModalCol>
                <ChooseMember
                  onChange={(data) =>
                    setTaskState({ ...taskState, task_owner: data })
                  }
                  onBlur={handleBlurMember}
                />
              </TaskModalCol>
            </TaskModalRow>
            <TaskModalRow>
              <TaskModalButtonCol>
                <TaskCreateButtonStyle
                  variant="primary"
                  type="submit"
                  // className={!(dirty && isValid) ? 'disabled-btn' : ''}
                  disabled={isSubmitting || !(dirty && isValid)}
                >
                  {isSubmitting ? 'Creating...' : 'Create'}
                </TaskCreateButtonStyle>

                <TaskCancelButtonStyle
                  variant="secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </TaskCancelButtonStyle>
              </TaskModalButtonCol>
            </TaskModalRow>
          </Form.Group>
        </Form>
      </TaskModalBody>
    </TaskModalStyle>
  );
};

export default TaskModal;

TaskModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
};

ChooseMember.propTypes = {
  onChange: PropTypes.func,
};

// ArrayInput.propTypes = {
//   id: PropTypes.string,
//   name: PropTypes.string,
//   label: PropTypes.string,
// };
// FileTypeBlock.propTypes = {
//   fileType: PropTypes.string,
//   dateValues: PropTypes.oneOfType([PropTypes.object]),
//   otherFileValues: PropTypes.string,
//   onChange: PropTypes.func,
//   placeholder: PropTypes.string,
// };
