import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PlusCircleFill,
  PencilSquare,
  XCircleFill,
} from 'react-bootstrap-icons';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import { useQuery } from '@tanstack/react-query';

import { boardMembersRoles } from '../api/board';
import {
  StyledDatePicker,
  StyledDatePickerButton,
  StyledDatePickerSubtaskInfo,
  StyledSelect,
  SubTaskHourInfo,
  SubTaskInput,
} from './subtaskStyle';

export const HectaresInfo = (props) => {
  const [subTaskHectare, setSubTaskHectare] = useState(props.hectares);
  const handleChangeHectare = (e) => {
    setSubTaskHectare(Number(e.target.value));

    props.onChange(Number(e.target.value));
  };

  return (
    <SubTaskInput
      type="number"
      value={subTaskHectare}
      onChange={handleChangeHectare}
      min="0"
    />
  );
};

export const FieldsInfo = (props) => {
  const [subTaskFields, setSubTaskFields] = useState(props.fields);

  const handleChangeFields = (e) => {
    setSubTaskFields(Number(e.target.value));
    props.onChange(Number(e.target.value));
  };

  return (
    <SubTaskInput
      value={subTaskFields}
      onChange={handleChangeFields}
      min="0"
      type="number"
    />
  );
};

export const HoursInfo = (props) => {
  const durrArr = props.subtaskDuration.split(':');
  const [hours, setHours] = useState(durrArr[0]);
  const [minutes, setMinutes] = useState(durrArr[1]);

  const handleChangeHours = (e) => {
    setHours(e.target.value);
  };
  const handleChangeMinutes = (e) => {
    setMinutes(e.target.value);
  };

  props.onChange(`${hours}:${minutes}`);
  return (
    <SubTaskHourInfo>
      <SubTaskInput
        value={hours}
        onChange={handleChangeHours}
        min="0"
        type="number"
      />
      <span>:</span>

      <SubTaskInput
        value={minutes}
        onChange={handleChangeMinutes}
        min="0"
        max="59"
        type="number"
      />
    </SubTaskHourInfo>
  );
};

export const ChooseDelineationMember = (props) => {
  const [toggle, setToggle] = useState(props.userName);
  const [isEditing, setIsEditing] = useState(false);
  const role = props.role.includes('Delineator') ? 'delineator' : 'reviewer';

  const people = useQuery(['boardMembersRoles', role], () =>
    boardMembersRoles(role)
  );
  if (people.isLoading) {
    return <div>Loading.</div>;
  }

  const nameRole = people.data;

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleClick = (e) => {
    setToggle(e.target.value);
    props.onClick([e.target.value, e.target[e.target.selectedIndex].id]);
    setIsEditing(false);
  };

  return (
    <div>
      <p>{props.role}</p>
      {isEditing ? (
        <div>
          <StyledSelect
            value={toggle}
            onChange={handleClick}
            style={{ width: '60%' }}
          >
            <option value="" disabled>
              Select a member
            </option>
            {nameRole.map((list, index) => (
              <option
                key={list.user_id}
                value={list.name}
                title={list.email}
                id={list.user_id}
              >
                {list.name}
              </option>
            ))}
          </StyledSelect>
          <XCircleFill onClick={handleCancelClick} />
        </div>
      ) : (
        <div>
          <span>{toggle}</span>
          <PencilSquare onClick={handleEditClick} />
        </div>
      )}
    </div>
  );
};

export const DueDateForDelineation = (props) => {
  const [startDate, setDate] = React.useState(
    props.dueDate === '' ? props.dueDate : new Date(props.dueDate)
  );
  const [isDateOpen, setIsDateOpen] = useState(false);

  const selectDateHandler = (d) => {
    setIsDateOpen(!isDateOpen);
    setDate(d);

    props.onClick(d);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsDateOpen(!isDateOpen);
  };

  return (
    <StyledDatePickerSubtaskInfo>
      <StyledDatePickerButton type="text" onClick={handleClick} variant="Light">
        {startDate === '' ? (
          <PlusCircleFill />
        ) : (
          moment.utc(startDate).local().format('DD-MM-YYYY')
        )}
      </StyledDatePickerButton>

      {isDateOpen && (
        <StyledDatePicker
          dateFormat="yyyy/MM/dd"
          selected={startDate}
          onChange={selectDateHandler}
          inline
        />
      )}
    </StyledDatePickerSubtaskInfo>
  );
};

export const statusOfSubtask = () => {};

HectaresInfo.propTypes = {
  hectares: PropTypes.number,
  onChange: PropTypes.func,
};

FieldsInfo.propTypes = {
  fields: PropTypes.number,
  onChange: PropTypes.func,
};

HoursInfo.propTypes = {
  subtaskDuration: PropTypes.string,
  onChange: PropTypes.func,
};

ChooseDelineationMember.propTypes = {
  role: PropTypes.string,
  onClick: PropTypes.func,
  // userValue: PropTypes.oneOfType([PropTypes.object]),
  userName: PropTypes.string,
};

DueDateForDelineation.propTypes = {
  dueDate: PropTypes.string,
  onClick: PropTypes.func,
};
