import './App.css';
import Amplify from 'aws-amplify';

import React, { useEffect } from 'react';

import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AmplifyConfig from './constants/amplify';

import { loadCurrentUserAsync } from './pages/auth/api';

import { useAuthContext } from './pages/auth/contexts/index';
import {
  startLoadCurrentUser,
  loadCurrentUserSuccess,
  loadCurrentUserFail,
} from './pages/auth/contexts/actions';

import AuthRoute from './pages/auth/AuthRoute';
import SignIn from './pages/auth/Signin';
import Board from './pages/gis/board';

import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(AmplifyConfig);

const queryClient = new QueryClient();

const App = () => {
  const [authState, authDispatch] = useAuthContext();

  useEffect(() => {
    authDispatch(startLoadCurrentUser());

    const loadCurrentSession = async () => {
      try {
        const user = await loadCurrentUserAsync();
        if (user) {
          authDispatch(loadCurrentUserSuccess(user.attributes));
        } else {
          authDispatch(loadCurrentUserFail());
        }
      } catch (err) {
        authDispatch(
          loadCurrentUserFail(err.message || 'Something went wrong')
        );
      }
    };
    loadCurrentSession();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {!authState.isValidatingSession ? (
        <Router>
          <Routes>
            <Route path="/" element={<AuthRoute authUser={authState.user} />}>
              <Route path="/" element={<Navigate to="/gis-tool" replace />} />

              <Route exact path="/gis-tool" element={<Board />} />
            </Route>

            <Route exact path="/sign-in" element={<SignIn />} />

            <Route path="*" element={<Navigate to="/gis-tool" replace />} />
          </Routes>
        </Router>
      ) : (
        <div className="loading">
          <img alt="logo" src="/logo.png" />
        </div>
      )}
    </QueryClientProvider>
  );
};

export default App;
