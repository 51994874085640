import axios from 'axios';
import { get, put } from '../../../utils/API';
import config from '../../../constants/test_config';

const boardId = 1;

export const getSubtask = async (subtaskId) => {
  // gis_board_id
  const response = await get(`/subtasks/${subtaskId}`);
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );
  // const response = await axios.get(
  //   `http://localhost:8000/subtasks/${subtaskId}`,
  //   config
  // );

  return response;
};

export const updateSubtask = async (subtaskId, params) => {
  const res = await put(`/subtasks/${subtaskId}`, params);

  // const res = await axios.put(
  //   `http://localhost:8000/subtasks/${subtaskId}`,
  //   params,
  //   config
  // );
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return res;
};
