import axios from 'axios';
import { get, put } from '../../../utils/API';

import config from '../../../constants/test_config';

export const getMe = async (userId) => {
  // gis_board_id
  const response = await get(`/me?user_id=${userId}`);
  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  // const response = await axios.get(
  //   `http://localhost:8000/me/?user_id=${userId}`,
  //   config
  // );

  // return response;
  return response;
};

export const getProfileInfo = async () => {};
