import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ProfileBar,
  InsightBoardProfile,
  InsightBoardProfileItems,
} from './profileStyle';

const Profile = ({ profiledata }) => {
  const [totalHectare, setTotalHectare] = useState(0);
  const [totalFields, setTotalFields] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    let newTotalHectare = 0;
    let newTotalFields = 0;
    let newTotalMinutes = 0;

    profiledata.insights.forEach((pdata) => {
      if (profiledata.Profile.BoardMembers.user_id === pdata.id) {
        const [Hours, Minutes] = pdata.Hours.split(':').map(Number);
        const TotalMinutes = Hours * 60 + Minutes;
        newTotalHectare += pdata.Hectares;
        newTotalFields += pdata.Fields;
        newTotalMinutes += TotalMinutes;
      }
    });

    setTotalHectare(newTotalHectare);
    setTotalFields(newTotalFields);
    setTotalHours(newTotalMinutes / 60);
  }, [profiledata]);

  return (
    <ProfileBar>
      {profiledata.Profile.BoardMembers.role === 'admin' ||
      profiledata.Profile.BoardMembers.role === 'super_admin' ? (
        <div>
          <InsightBoardProfile>
            <InsightBoardProfileItems>
              {profiledata.Profile.BoardMembers.role_display_name}
            </InsightBoardProfileItems>
            <InsightBoardProfileItems>Role</InsightBoardProfileItems>
          </InsightBoardProfile>
          <InsightBoardProfile>
            <InsightBoardProfileItems>
              {profiledata.Profile.User.username}
            </InsightBoardProfileItems>
            <InsightBoardProfileItems>Username</InsightBoardProfileItems>
          </InsightBoardProfile>{' '}
        </div>
      ) : (
        <div>
          <InsightBoardProfile>
            <InsightBoardProfileItems>{totalHectare}</InsightBoardProfileItems>
            <InsightBoardProfileItems>Total Hectares</InsightBoardProfileItems>
          </InsightBoardProfile>
          <InsightBoardProfile>
            <InsightBoardProfileItems>{totalFields}</InsightBoardProfileItems>
            <InsightBoardProfileItems>Total Fields</InsightBoardProfileItems>
          </InsightBoardProfile>
          <InsightBoardProfile>
            <InsightBoardProfileItems>
              {`${Math.floor(totalHours)}:${Math.floor((totalHours % 1) * 60)}`}
            </InsightBoardProfileItems>
            <InsightBoardProfileItems>Total Hours</InsightBoardProfileItems>
          </InsightBoardProfile>
          <InsightBoardProfile>
            <InsightBoardProfileItems>
              {profiledata.Profile.BoardMembers.role_display_name}
            </InsightBoardProfileItems>
            <InsightBoardProfileItems>Role</InsightBoardProfileItems>
          </InsightBoardProfile>
          <InsightBoardProfile>
            <InsightBoardProfileItems>
              {profiledata.Profile.User.username}
            </InsightBoardProfileItems>
            <InsightBoardProfileItems>Username</InsightBoardProfileItems>
          </InsightBoardProfile>
        </div>
      )}
    </ProfileBar>
  );
};

Profile.propTypes = {
  profiledata: PropTypes.oneOfType([PropTypes.object]),
};

export default Profile;
