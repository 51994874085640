import React, { useContext } from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { getTask } from '../pages/gis/api/tasks';

export const checkPermissions = (roleData, taskId) => {
  const taskInfo = useQuery(['getTask', taskId], () => getTask(taskId));
  // console.log('----in', taskInfo.data, roleData);

  if (taskInfo.isLoading) {
    return <div>Loading..</div>;
  }

  if (taskInfo.isError) {
    return <div>The request did not succeed.</div>;
  }

  if (taskInfo.data) {
    const sectionId = taskInfo.data.section_id;
    if (
      roleData.Profile.BoardMembers.role === 'reviewer' &&
      (taskInfo.data.created_by === roleData.Profile.User.username ||
        taskInfo.data.task_owner === roleData.Profile.User.name)
    ) {
      return true;
    }

    if (
      roleData.Profile.BoardMembers.role === 'admin' ||
      roleData.Profile.BoardMembers.role === 'super_admin'
    ) {
      return true;
    }

    return false;
  }
};

export const TaskAccessControl = ({
  roleData,
  taskId,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(roleData, taskId);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

TaskAccessControl.defaultProps = {
  renderNoAccess: () => null,
};
