import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TaskModal from './Modals/taskModal';
import { CreateTaskButton, CreateTaskNavBar } from './taskStyle';

const CreateTask = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setModalShow(false);
    setIsSubmitting(false);
  };

  const handleShow = () => {
    if (!isSubmitting) {
      setModalShow(true);
      setIsSubmitting(true);
    }
  };

  return (
    <CreateTaskNavBar>
      <CreateTaskButton
        type="button"
        onClick={handleShow}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Adding...' : 'Add a task'}
      </CreateTaskButton>

      <TaskModal
        show={modalShow}
        onClose={handleClose}
        heading="Success"
        message="Hello"
        boardQuery={props.boardQuery}
      />
    </CreateTaskNavBar>
  );
};

CreateTask.propTypes = {
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
};

export default CreateTask;
