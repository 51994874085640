import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const BoardStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  padding-top: 1.5rem;
  margin-left: 5rem;
  margin-right: 4rem;
`;

export const ColumnStyle = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  max-width: 100vw;
  height: 100vw;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  /* &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  } */
`;

export const EachColumnStyle = styled.div`
  background-color: rgba(230, 230, 236, 0.607);
  border-radius: 8px;
  margin-right: 4rem;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;
`;

export const ColumnName = styled.div`
  font-size: 12px;
  text-align: center;
  padding-bottom: 3em;
  height: 30px;
  width: 364px;
  margin: 1rem 0;
`;

export const BoardHeaderRow = styled(Row)`
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 0 3px #ccc;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 120px;
  position: sticky;
  top: 3.9rem;
  z-index: 10;
  margin-bottom: 1rem;
`;

export const BoardHeaderCol1 = styled(Col)`
  margin-left: 1rem;
  font-size: 17px;
  width: 446px;
  margin-top: 2.5rem;
  font-weight: 800;
`;

export const BoardHeaderCol2 = styled(Col)`
  border: 1px solid #ccc;
  box-shadow: -5px -5px 5px -5px #ccc;
  height: 76px;
  width: 674px;
  background-color: #defade;
  border-radius: 10px;
  margin-top: 1.5rem;
  margin-right: 1rem;
  display: table;
`;

export const BoardHeaderCol3 = styled(Col)`
  display: table;
`;

export const InsightBoard = styled(Col)`
  display: table-cell;
  padding-left: 1rem;
`;

export const InsightBoardItems = styled(Row)`
  width: 120px;
  &:nth-child(2) {
    font-size: 11px;
    color: #666666;
    letter-spacing: 0.1ch;
  }
  &:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ProfileContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const OverflowArrow = styled.div`
  position: fixed;
  bottom: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const RightOverflowArrow = styled(OverflowArrow)`
  right: 20px;
`;

export const LeftOverflowArrow = styled(OverflowArrow)`
  left: 20px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const TopOverflowArrow = styled(OverflowArrow)`
  right: 20px;
  bottom: 100px;
`;

export const DownOverflowArrow = styled(OverflowArrow)`
  right: 20px;
  bottom: 100px;
`;
