import styled, { css } from 'styled-components';
import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Container,
  Modal,
  Button,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export const TaskModalStyle = styled(Modal)`
  font-size: 12px;

  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */

  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
`;

export const TaskHeaderStyle = styled(Modal.Header)`
  font-size: 18px;
`;

export const TaskModalBody = styled(Modal.Header)`
  font-size: 15px;

  width: 100%;
`;

export const TaskModalRow = styled(Row)`
  display: table;
  margin-bottom: 1rem;
`;

export const TaskModalCol = styled(Col)`
  display: table-cell;
  width: 25%;
  align-items: center;
  justify-content: center;
`;

export const TaskInput = styled.input`
  padding: 5px;
  font-size: 12px;
  height: 35px;
  margin: 6px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
`;

export const TaskLabel = styled(Row)`
  margin: 6px 0;
  display: inline-block;
  font-size: 12px;
  width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  padding: 5px;
  font-size: 15px;
  margin: 6px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  &:focus {
    outline: none;
    border-color: black;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 35px;
  margin: 6px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23777' d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: black;
  }

  & option {
    background-color: #fff;
    color: #333;
    font-size: 12px;
  }
`;

export const TaskNotesArea = styled.textarea`
  font-size: 15px;
  margin-left: 0.7em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 464px;
`;

export const StyledDropdownButton = styled(DropdownButton)`
  /* font-size: 1rem;
  font-weight: 400; */
  line-height: 1.5;
  color: #212529;
  background-color: #fff;

  width: 25%;

  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:hover {
    color: #212529;
    background-color: #e9ecef;
    border-color: #cad1d7;
  }

  & .dropdown-toggle {
    border: none;
    background-color: white;
    color: rgb(13, 149, 34);
    font-size: 12px;
  }

  & .priority-btn:hover {
    border: none;
    background-color: white;
    color: rgb(13, 149, 34);
    font-size: 12px;
  }
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 12px;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
`;

export const TaskModalColPriority = styled(Col)`
  width: 20px;
  align-items: center;
  justify-content: center;
`;

export const TaskStyleListFiles = styled.ul`
  display: inline-block;
  vertical-align: top; /* This will align the lists to the top */
  margin: 0;
  padding: 2px;
  list-style: none;

  li {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 3px;
    margin-bottom: 3px;
    font-size: 12px;
    button {
      border: none;
      outline: none;
      background: none;
    }
  }
`;

export const TaskCreateButtonStyle = styled(Button)`
  width: 180px;
  margin-top: 2rem;
  margin-left: 1rem;
  flex: 1;
  float: left;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(1px);
  }
`;
export const TaskCancelButtonStyle = styled(Button)`
  margin-top: 2rem;
  margin-left: 3rem;
  background-color: #f32c52;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const TaskModalButtonCol = styled(Col)`
  display: flex;
  margin-left: 2rem;
  width: 100%;
`;

export const CreateTaskNavBar = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.2rem; */
`;

export const CreateTaskButton = styled(Button)`
  width: 120px;
  font-size: 13px;
  height: 45px;
  margin-top: 0.7rem;
  margin-left: 2rem;
  letter-spacing: 0.1ch;
`;

export const TaskContainerStyle = styled(Container)`
  font-size: 12px;
`;

export const TaskRowStyle = styled(Row)`
  align-items: center;
  text-align: center;
  margin-bottom: 0.2rem;
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const TaskColStyle = styled(Col)`
  background-color: rgba(230, 230, 236, 0.607);
  padding-top: 5px;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  margin: 4px; /* margin to create the gap */
`;

export const TaskColFooter = styled(Col)`
  padding-top: 5px;
  height: 100%;
  width: 100%;
  text-transform: capitalize;
`;

export const TaskColTitleStyle = styled(Col)`
  font-size: 15px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  ${(props) =>
    props.priority === 'urgent' &&
    css`
      color: #db0f27;
    `}

  ${(props) =>
    props.priority === 'high' &&
    css`
      color: #fc6a03;
    `}

  ${(props) =>
    props.priority === 'medium' &&
    css`
      color: #e6cc00;
      text-decoration: #c66900;
    `}

  ${(props) =>
    props.priority === 'low' &&
    css`
      color: green;
    `}
`;

export const TaskCompleteButton = styled(Button)`
  font-size: 12px;
`;

export const TaskDone = styled(Button)`
  background-color: ${(props) => (props.disabled ? 'gray' : 'blue')};
  color: white;

  border-radius: 5px;
  cursor: ${(props) => (props.disabled === false ? 'not-allowed' : 'pointer')};
  outline: none;
  border: none;
  text-align: center;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  left: 0;
  margin-bottom: 1rem;
  font-size: 12px;
`;
