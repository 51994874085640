import Button from 'react-bootstrap/Button';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import moment from 'moment';

import { Form, Row, Col } from 'react-bootstrap';

import {
  ChooseDelineationMember,
  FieldsInfo,
  HectaresInfo,
  HoursInfo,
  DueDateForDelineation,
} from '../subTaskInfo';
import { FormProvider } from '../subTaskFormContext';
import { updateSubtask } from '../../api/subtasks';
import AccessControl from '../../../../permissions/AccessControl';
import GisContext from '../../context/gisContext';

import {
  CalenderSubTask,
  DisplayDueDate,
  DueDateSubTask,
  StyledDatePicker,
  StyledDropdownButton,
  StyledDropdownItem,
  SubTaskHeaderStyle,
  SubTaskInfoTable,
  SubTaskLabel,
  SubTaskModalBody,
  SubTaskModalCol,
  SubTaskModalHeaderItems,
  SubTaskModalInfoRow,
  SubTaskModalRow,
  SubTaskModalStyle,
  SubTaskModalTextAreaRow,
  SubTaskNotesArea,
} from '../subtaskStyle';

const SubtaskModalWindow = (props) => {
  const {
    onClose,
    task,
    columnname,
    roledata,
    show,
    refetchSubtask,
    boardQuery,
  } = props;

  const [startDate, setDate] = React.useState(new Date(task.due_date));
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [subTask, setSubTask] = useState(task);
  const [subtaskInfo, setSubtaskInfo] = useState(task.subtask_info);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setTaskTrigger } = React.useContext(GisContext);
  useEffect(() => {
    if (!show) {
      setSubTask(task);
      setSubtaskInfo(task.subtask_info);
      setDate(new Date(task.due_date));
      setIsDateOpen(false);
    }
  }, [show, columnname, setIsDateOpen]);

  const selectDateHandler = (d) => {
    setIsDateOpen(!isDateOpen);
    setDate(new Date(d));
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsDateOpen(!isDateOpen);
  };

  const {
    values,
    errors,
    dirty,
    isValid,
    touched,

    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: { ...task },

    onSubmit: async (values, { setSubmitting }) => {
      setIsSubmitting(true);
      const updatedSubtask = await updateSubtask(task.id, {
        ...values,

        due_date: new Date(startDate).toISOString(),

        subtask_info: {
          '01-Delineator': [
            {
              DueDate:
                subtaskInfo['01-Delineator'][0].DueDate !== ''
                  ? new Date(subtaskInfo['01-Delineator'][0].DueDate)
                      .toISOString()
                      .replace('Z', '')
                  : '',
              Hours: subtaskInfo['01-Delineator'][0].Hours,
              Fields: subtaskInfo['01-Delineator'][0].Fields,
              Hectares: subtaskInfo['01-Delineator'][0].Hectares,
              id: subtaskInfo['01-Delineator'][0].id,
              userName: subtaskInfo['01-Delineator'][0].userName,
            },
          ],
          '02-First Reviewer': [
            {
              DueDate:
                subtaskInfo['02-First Reviewer'][0].DueDate !== ''
                  ? new Date(subtaskInfo['02-First Reviewer'][0].DueDate)
                      .toISOString()
                      .replace('Z', '')
                  : '',
              Hours: subtaskInfo['02-First Reviewer'][0].Hours,
              Fields: subtaskInfo['02-First Reviewer'][0].Fields,
              Hectares: subtaskInfo['02-First Reviewer'][0].Hectares,
              id: subtaskInfo['02-First Reviewer'][0].id,
              userName: subtaskInfo['02-First Reviewer'][0].userName,
            },
          ],
          '03-Final Reviewer': [
            {
              DueDate:
                subtaskInfo['03-Final Reviewer'][0].DueDate !== ''
                  ? new Date(subtaskInfo['03-Final Reviewer'][0].DueDate)
                      .toISOString()
                      .replace('Z', '')
                  : '',
              Hours: subtaskInfo['03-Final Reviewer'][0].Hours,
              Fields: subtaskInfo['03-Final Reviewer'][0].Fields,
              Hectares: subtaskInfo['03-Final Reviewer'][0].Hectares,
              id: subtaskInfo['03-Final Reviewer'][0].id,
              userName: subtaskInfo['03-Final Reviewer'][0].userName,
            },
          ],
        },
      });

      onClose();
      await boardQuery.refetch();
      refetchSubtask();
      setTaskTrigger(true);
      setSubmitting(false);
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    resetForm({ values: { ...task } });
  }, [task, resetForm]);

  const isDueDateExpired = moment.utc(startDate).isBefore(moment(), 'day');

  return (
    <SubTaskModalStyle
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <SubTaskHeaderStyle closeButton>
        <SubTaskModalHeaderItems>{task.tile}</SubTaskModalHeaderItems>
        <SubTaskModalHeaderItems>{task.year}</SubTaskModalHeaderItems>
        <SubTaskModalHeaderItems>v{task.version}</SubTaskModalHeaderItems>
        <SubTaskModalHeaderItems>Area {task.area}</SubTaskModalHeaderItems>
        <SubTaskModalHeaderItems
          style={{
            color: isDueDateExpired ? 'red' : 'green',
          }}
        >
          {/* <Timechange date={task.due_date} /> */}
          {format(startDate, 'dd-MM-yyyy')}
        </SubTaskModalHeaderItems>
      </SubTaskHeaderStyle>
      <SubTaskModalBody>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <FormProvider value={{ subTask, setSubTask }}>
              <SubTaskModalRow>
                <SubTaskModalCol>
                  <CalenderSubTask>
                    <DueDateSubTask
                      type="text"
                      onClick={handleClick}
                      variant="Light"
                    >
                      Select Due Date{' '}
                      {!isDateOpen ? <ChevronDown /> : <ChevronUp />}
                    </DueDateSubTask>
                    <SubTaskLabel>
                      {format(startDate, 'dd-MM-yyyy')}
                    </SubTaskLabel>
                    {isDateOpen && (
                      <StyledDatePicker
                        dateFormat="yyyy/MM/dd"
                        selected={startDate}
                        onChange={selectDateHandler}
                        inline
                      />
                    )}
                  </CalenderSubTask>
                </SubTaskModalCol>
                <SubTaskModalCol>
                  <StyledDropdownButton
                    title="Status"
                    variant="Light"
                    id="section_status_id"
                    name="section_status_id"
                    value={values.section_status_id}
                    onSelect={(e) => setFieldValue('section_status_id', e)}
                  >
                    {Object.keys(columnname)
                      .filter((e) => e !== 'wf1' && e !== 'wf9')
                      .map((val, index) => (
                        <StyledDropdownItem eventKey={val}>
                          {columnname[val]}
                        </StyledDropdownItem>
                      ))}
                  </StyledDropdownButton>
                  <SubTaskLabel>
                    {columnname[values.section_status_id]}
                  </SubTaskLabel>
                </SubTaskModalCol>
                <SubTaskModalCol>
                  {/* <DropDownPriority subtaskPriority={task.priority} />
                <Row>{task.priority}</Row> */}
                  <StyledDropdownButton
                    name="priority"
                    value={values.priority}
                    onSelect={(e) => setFieldValue('priority', e)}
                    title="Priority"
                    variant="Light"
                  >
                    <StyledDropdownItem eventKey="urgent">
                      Urgent
                    </StyledDropdownItem>
                    {/* <Dropdown.Item>Results</Dropdown.Item> */}
                    <StyledDropdownItem eventKey="high">
                      High
                    </StyledDropdownItem>
                    <StyledDropdownItem eventKey="medium">
                      Medium
                    </StyledDropdownItem>
                    <StyledDropdownItem eventKey="low">Low</StyledDropdownItem>
                  </StyledDropdownButton>
                  <SubTaskLabel priority={values.priority}>
                    {values.priority}
                  </SubTaskLabel>
                </SubTaskModalCol>
              </SubTaskModalRow>
              <SubTaskModalTextAreaRow>
                {subTask.file_info.files.map((e) => (
                  <SubTaskLabel>{e}</SubTaskLabel>
                ))}
              </SubTaskModalTextAreaRow>
              <SubTaskModalTextAreaRow>
                <SubTaskNotesArea
                  value={values.subtask_notes}
                  onChange={(e) =>
                    setFieldValue('subtask_notes', e.target.value)
                  }
                  cols={30}
                  rows={5}
                />
              </SubTaskModalTextAreaRow>
              <SubTaskModalInfoRow>
                <SubTaskInfoTable>
                  <thead>
                    <tr>
                      <th>Delineation</th>
                      <th>Due Date</th>
                      <th>Hectares</th>
                      <th>Fields</th>
                      <th>Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(subtaskInfo).map((sbtsk) => (
                      <AccessControl
                        userPermissions={roledata.permissions}
                        allowedPermissions={[
                          'subtask/review',
                          'subtask/delineationInput',
                        ]}
                      >
                        <tr>
                          <td>
                            {/* <PlusCircleFill />
                        Assign {sbtsk} */}

                            <ChooseDelineationMember
                              role={sbtsk}
                              userName={subtaskInfo[sbtsk][0].userName}
                              onClick={(data) => {
                                [
                                  subtaskInfo[sbtsk][0].userName,
                                  subtaskInfo[sbtsk][0].id,
                                ] = data;
                              }}
                            />
                          </td>

                          <td>
                            <DueDateForDelineation
                              dueDate={subtaskInfo[sbtsk][0].DueDate}
                              onClick={(data) => {
                                subtaskInfo[sbtsk][0].DueDate = data;
                              }}
                            />
                          </td>
                          <td>
                            <HectaresInfo
                              hectares={subtaskInfo[sbtsk][0].Hectares}
                              onChange={(data) => {
                                subtaskInfo[sbtsk][0].Hectares = data;
                              }}
                            />
                          </td>
                          <td>
                            <FieldsInfo
                              fields={subtaskInfo[sbtsk][0].Fields}
                              onChange={(data) => {
                                subtaskInfo[sbtsk][0].Fields = data;
                              }}
                            />
                          </td>
                          <td>
                            <HoursInfo
                              subtaskDuration={subtaskInfo[sbtsk][0].Hours}
                              onChange={(data) => {
                                subtaskInfo[sbtsk][0].Hours = data;
                              }}
                            />
                          </td>
                        </tr>
                      </AccessControl>
                    ))}
                  </tbody>
                </SubTaskInfoTable>
              </SubTaskModalInfoRow>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className={isSubmitting ? 'disabled-btn' : ''}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </Col>
              </Row>
            </FormProvider>
          </Form.Group>
        </Form>
      </SubTaskModalBody>
    </SubTaskModalStyle>
  );
};

SubtaskModalWindow.propTypes = {
  onClose: PropTypes.func,
  task: PropTypes.oneOfType([PropTypes.object]),
  show: PropTypes.bool,
  key: PropTypes.string,
  columnname: PropTypes.oneOfType([PropTypes.object]),
  roledata: PropTypes.oneOfType([PropTypes.object]),
  refetchSubtask: PropTypes.func,
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
};

export default SubtaskModalWindow;
