import React from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { getSubtask } from '../pages/gis/api/subtasks';

export const checkPermissions = (roleData, subtaskId) => {
  const subtaskInfo = useQuery(['getSubtask', subtaskId], () =>
    getSubtask(subtaskId)
  );

  if (subtaskInfo.isLoading) {
    return <div>Loading..</div>;
  }

  if (subtaskInfo.isError) {
    return <div>The request did not succeed.</div>;
  }

  if (subtaskInfo.data) {
    // console.log('----in', subtaskInfo.data, roleData.Profile.BoardMembers.role);
    if (
      (roleData.Profile.BoardMembers.role === 'delineator' &&
        subtaskInfo.data.subtask_info['01-Delineator'][0].id ===
          roleData.Profile.BoardMembers.user_id) ||
      roleData.Profile.BoardMembers.role === 'admin' ||
      roleData.Profile.BoardMembers.role === 'super_admin'
    ) {
      return true;
    }

    if (
      (roleData.Profile.BoardMembers.role === 'reviewer' &&
        subtaskInfo.data.created_by === roleData.Profile.User.username) ||
      subtaskInfo.data.subtask_info['02-First Reviewer'][0].id ===
        roleData.Profile.BoardMembers.user_id ||
      subtaskInfo.data.subtask_info['03-Final Reviewer'][0].id ===
        roleData.Profile.BoardMembers.user_id
    ) {
      return true;
    }

    return false;
  }
};

export const SubtaskAccessControl = ({
  roleData,
  subtaskId,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(roleData, subtaskId);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

SubtaskAccessControl.defaultProps = {
  renderNoAccess: () => null,
};
