import axios from 'axios';
import { get, post } from '../../../utils/API';
import config from '../../../constants/test_config';

const boardId = 1;

export const listBoardItems = async () => {
  // gis_board_id
  const response = await get(`/boards/${boardId}`);
  // const response = await axios.get(
  //   `http://localhost:8000/boards/${boardId}`,
  //   config
  // );

  return response;
};

export const boardMembers = async () => {
  const response = await get(`/boards/${boardId}/members`);
  // const response = await axios.get(
  //   `http://localhost:8000/boards/${boardId}/members`,
  //   config
  // );

  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return response;
};

export const boardMembersRoles = async (role) => {
  const response = await get(`/boards/${boardId}/members?role=${role}`);

  // const response = await axios.get(
  //   `http://localhost:8000/boards/${boardId}/members?role=${role}`,
  //   config
  // );

  // const response = await axios.get(
  //   "https://development.kvasir.digifarm.io/jobs"
  // );

  return response;
};
