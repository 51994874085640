import styled, { css } from 'styled-components';

export const TaskCard = styled.div`
  width: 364px;
  /* height: 200px; */
  margin: 0.7rem 0;
  /* padding: 1.2rem 1rem 0.7rem 1rem; */

  margin-left: 2rem;
  margin-right: 2rem;

  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  background-color: rgb(253, 254, 255);
  overflow: auto;

  ${(props) =>
    props.isTranslucent &&
    css`
      opacity: 0.5;
    `}
`;

export const SubTaskCard = styled.div`
  width: 304px;
  height: 130px;
  margin: 0.7rem 0;
  /* padding: 1.2rem 1rem 0.7rem 1rem; */

  margin-left: 2rem;

  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  background-color: rgb(253, 254, 255);
  overflow: auto;

  ${(props) =>
    props.isTranslucent &&
    css`
      opacity: 0.5;
    `}
`;
