import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { getSubtask } from '../api/subtasks';

import SubtaskModalWindow from './Modals/subTaskModal';
import {
  SubTaskCardStyle,
  SubTaskCardStyleRow,
  TaskColStyle,
} from './subtaskStyle';
// import { TaskColStyle } from '../tasks/taskStyle';

const Subtasks = ({ subtaskId, columnName, roleData, boardQuery }) => {
  const [modalShow, setModalShow] = useState(false);
  const [subtaskData, setSubtaskData] = useState(null);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const queryClient = useQueryClient();
  const subtaskInfo = useQuery(['getSubtask', subtaskId], () =>
    getSubtask(subtaskId)
  );

  const refetchSubtask = () => {
    queryClient.invalidateQueries(['getSubtask', subtaskId]);
  };

  useEffect(() => {
    if (subtaskInfo.data) {
      setSubtaskData(subtaskInfo.data);
    }
  }, [subtaskInfo.data]);

  if (subtaskInfo.isLoading || !subtaskData) {
    return <div>Loading..</div>;
  }

  if (subtaskInfo.isError) {
    return <div>The request did not succeed.</div>;
  }
  const isDueDateExpired = moment
    .utc(subtaskData.due_date)
    .isBefore(moment(), 'day');
  return (
    <div>
      <SubTaskCardStyle onClick={handleShow}>
        <SubTaskCardStyleRow priority={subtaskData && subtaskData.priority}>
          <Col>{subtaskData.tile}</Col>
          <Col priority={subtaskData && subtaskData.priority}>
            {subtaskData.priority}
          </Col>
        </SubTaskCardStyleRow>
        <SubTaskCardStyleRow>
          <TaskColStyle>{subtaskData.year}</TaskColStyle>
          <TaskColStyle>V{subtaskData.version}</TaskColStyle>
          <TaskColStyle>Area {subtaskData.area}</TaskColStyle>
        </SubTaskCardStyleRow>
        <SubTaskCardStyleRow>
          <TaskColStyle>{subtaskData.country_code}</TaskColStyle>
          <TaskColStyle
            style={{
              color: isDueDateExpired ? 'red' : 'green',
            }}
          >
            {moment.utc(subtaskData.due_date).local().format('DD-MM-YYYY')}
          </TaskColStyle>
        </SubTaskCardStyleRow>
      </SubTaskCardStyle>
      <SubtaskModalWindow
        task={subtaskData}
        show={modalShow}
        onClose={handleClose}
        heading="Success"
        message="Hello"
        columnname={columnName}
        roledata={roleData}
        refetchSubtask={refetchSubtask}
        boardQuery={boardQuery}
      />
    </div>
  );
};

Subtasks.propTypes = {
  subtaskId: PropTypes.string,
  columnName: PropTypes.oneOfType([PropTypes.object]),
  roleData: PropTypes.oneOfType([PropTypes.object]),
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
};

export default Subtasks;
