import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tasks from '../tasks';
import Subtasks from '../subtasks';

import {
  SubtaskAccessControl,
  checkPermissions,
} from '../../../permissions/SubTaskAccessControl';
import { SubTaskCard, TaskCard } from './cardStyle';
import { TaskAccessControl } from '../../../permissions/TaskAccessControl';

const Card = ({ cardArray, index, column, status, roleData, boardQuery }) => {
  // console.log('Welcome', boardQuery.data, 'status', status);
  // console.log('cardArray', cardArray);

  // let filteredCards = cardArray;

  // if (roleData?.Profile.BoardMembers.role === 'reviewer') {
  //   filteredCards = cardArray.filter((cardId) => {
  //     console.log('cardId', cardId);
  //     const card = boardQuery.data.all_contents[status].find(
  //       (item) => item.id === cardId
  //     );
  //     console.log('card', card, 'created_by', card?.created_by);
  //     console.log(card && card.created_by === roleData?.Profile.User.username);
  //     return card && card.created_by === roleData?.Profile.User.username;
  //   });

  //   console.log('filteredCards', filteredCards);
  // }

  if (status === 'wf1' || status === 'wf9') {
    // Render Tasks component
    return cardArray.map((card) => (
      <div key={`${card}-${index}`}>
        <TaskAccessControl roleData={roleData} taskId={card}>
          <TaskCard isTranslucent={status === 'wf9'}>
            <Tasks
              taskId={card}
              columnName={column}
              roleData={roleData}
              boardQuery={boardQuery}
            />
          </TaskCard>
        </TaskAccessControl>
      </div>
    ));
  }

  if (
    ['wf4', 'wf5', 'wf6', 'wf7', 'wf8'].includes(status) &&
    roleData?.Profile.BoardMembers.role === 'delineator'
  ) {
    // Render all Subtasks components with translucency
    return cardArray.map((card) => (
      <div key={`${card}-${index}`}>
        <SubtaskAccessControl roleData={roleData} subtaskId={card}>
          <SubTaskCard isTranslucent={!false}>
            <Subtasks
              subtaskId={card}
              columnName={column}
              roleData={roleData}
              boardQuery={boardQuery}
            />
          </SubTaskCard>
        </SubtaskAccessControl>
      </div>
    ));
  }

  // Render all Subtasks components without translucency
  return cardArray.map((card) => (
    <div key={`${card}-${index}`}>
      <SubtaskAccessControl roleData={roleData} subtaskId={card}>
        <SubTaskCard isTranslucent={status === 'wf8'}>
          <Subtasks
            subtaskId={card}
            columnName={column}
            roleData={roleData}
            boardQuery={boardQuery}
          />
        </SubTaskCard>
      </SubtaskAccessControl>
    </div>
  ));
};

Card.propTypes = {
  column: PropTypes.oneOfType([PropTypes.object]),
  cardArray: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  status: PropTypes.string,
  roleData: PropTypes.oneOfType([PropTypes.object]),
  boardQuery: PropTypes.oneOfType([PropTypes.object]),
};

export default Card;
