import { API } from 'aws-amplify';

const API_NAME = 'public';

export const post = async (path, payload = {}, headers = {}) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.post(API_NAME, path, {
      headers: payloadHeaders,
      body: payload,
    });
  } catch (err) {
    const { status, data } = err.response;
    // console.log('Error: ', err.response);
    // console.log(data, status);

    return Promise.reject(
      new Error({
        message: data && data.message,
        type: data && data.type,
        statusCode: status,
      })
    );
  }
};

export const get = async (
  path,
  queryStringParameters = {},
  headers = {},
  api = API_NAME
) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.get(api, path, {
      headers: payloadHeaders,
      queryStringParameters,
    });
  } catch (err) {
    const {
      status,
      data: { message, type },
    } = err.response;
    console.log('Error: ', err.response);
    console.log(message, type, status);

    return Promise.reject(err);
  }
};

export const patch = async (path, payload = {}, headers = {}) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.patch(API_NAME, path, {
      headers: payloadHeaders,
      body: payload,
    });
  } catch (err) {
    const { status, data } = err.response;

    return Promise.reject(
      new Error({
        message: data && data.message,
        type: data && data.type,
        statusCode: status,
      })
    );
  }
};

export const destroy = async (path, headers = {}, api = API_NAME) => {};

export const put = async (path, payload = {}, headers = {}, api = API_NAME) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.put(api, path, {
      headers: payloadHeaders,
      body: payload,
    });
  } catch (err) {
    const { status, data } = err.response;
    // console.log('Error: ', err.response);
    // console.log(data, status);

    return Promise.reject(
      new Error({
        message: data && data.message,
        type: data && data.type,
        statusCode: status,
      })
    );
  }
};

export const del = async (path, headers = {}, api = API_NAME) => {
  const payloadHeaders = {
    ...headers,
  };

  try {
    return await API.del(api, path, {
      headers: payloadHeaders,
    });
  } catch (err) {
    const { status, data } = err.response;
    return Promise.reject(
      new Error({
        message: data && data.message,
        type: data && data.type,
        statusCode: status,
      })
    );
  }
};
