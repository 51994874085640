import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const ProfileBar = styled(Col)`
  border: 1px solid #ccc;
  box-shadow: -5px -5px 5px -5px #ccc;
  height: 76px;
  width: 600px;
  background-color: #defade;
  border-radius: 10px;
  margin-top: 1.5rem;
  margin-right: 3rem;
  display: table;
  margin-bottom: 4rem;
  margin-left: 1rem;
`;

export const InsightBoardProfile = styled(Col)`
  display: table-cell;
  padding-left: 2.3rem;
  padding-top: 1rem;
`;

export const InsightBoardProfileItems = styled(Row)`
  &:nth-child(2) {
    font-size: 11px;
    color: #666666;
    letter-spacing: 0.1ch;
  }
  &:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
  }
`;
