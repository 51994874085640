import styled, { css } from 'styled-components';
import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Container,
  Modal,
  Button,
  Table,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const SubTaskModalStyle = styled(Modal)`
  font-size: 12px;

  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */

  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
`;
export const TaskColStyle = styled(Col)`
  background-color: rgba(230, 230, 236, 0.607);
  padding-top: 5px;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  margin: 4px; /* margin to create the gap */
`;

export const SubTaskHeaderStyle = styled(Modal.Header)`
  font-size: 12px;
  font-display: block;
`;

export const SubTaskModalHeaderItems = styled(Modal.Header)`
  padding-right: 2rem;
  /* margin-right: 1rem; */
  text-align: center;
  border: none;
`;

export const SubTaskModalBody = styled(Modal.Header)`
  font-size: 15px;

  width: 100%;
`;

export const SubTaskModalRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  position: relative;
  margin-left: 1px;
`;

export const SubTaskModalTextAreaRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 1rem;

  margin-left: 1rem;
`;

export const SubTaskModalInfoRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 1rem;

  margin-left: 1rem;
  margin-right: 1rem;
`;

export const SubTaskModalCol = styled(Col)`
  flex: 1;
  height: 80px;
  width: 120px;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
`;

export const SubTaskInput = styled.input`
  padding: 5px;
  font-size: 12px;
  height: 35px;
  margin: 6px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 50%;
`;

export const SubTaskLabel = styled(Row)`
  margin: 6px 0;
  display: inline-block;
  font-size: 12px;
  width: 100%;
  margin-left: 10px;
  text-transform: capitalize;
  color: ${(props) => {
    if (props.priority === 'urgent') return '#db0f27';
    if (props.priority === 'high') return '#fc6a03';
    if (props.priority === 'medium') return '#e6cc00';
    if (props.priority === 'low') return 'green';
    return 'inherit';
  }};

  &:first-child {
    ${(props) =>
      props.priority === 'urgent' &&
      css`
        color: #db0f27;
      `}

    ${(props) =>
      props.priority === 'high' &&
      css`
        color: #fc6a03;
      `}

    ${(props) =>
      props.priority === 'medium' &&
      css`
        color: #e6cc00;
      `}

    ${(props) =>
      props.priority === 'low' &&
      css`
        color: green;
      `}
  }
`;

export const CalenderSubTask = styled.div`
  position: absolute;
  /* width: 50%; */
  opacity: 1.3;
  /* padding-bottom: 2rem; */
`;

export const DueDateSubTask = styled.div`
  border: none;
  background-color: transparent !important;
  color: rgb(13, 149, 34);
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 0;
  padding-left: 0;
  font-size: 12px;
  width: 140px;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`;

export const DisplayDueDate = styled.div`
  font-size: 12px;
`;

export const StyledDatePicker = styled(DatePicker)`
  padding: 5px;
  font-size: 15px;
  margin: 6px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 1;
  &:focus {
    outline: none;
    border-color: black;
  }
`;

export const StyledDatePickerSubtaskInfo = styled.div`
  position: absolute;
  width: 10%;

  &:focus {
    outline: none;
    border-color: black;
  }
  .react-datepicker {
    z-index: 1;
  }
`;

export const StyledDatePickerButton = styled(Button)`
  border: none;
  background-color: transparent !important;
  color: rgb(13, 149, 34);
  margin-left: 0;
  padding-left: 0;
  font-size: 12px;
  width: 75px;
  font-size: 12px;
  text-align: left;
  &:focus {
    outline: none;
    border-color: black;
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 35px;
  margin: 6px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23777' d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: black;
  }

  & option {
    background-color: #fff;
    color: #333;
    font-size: 12px;
  }
`;

export const SubTaskNotesArea = styled.textarea`
  font-size: 15px;
  margin-left: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 432px;
`;

export const StyledDropdownButton = styled(DropdownButton)`
  /* font-size: 1rem;
  font-weight: 400; */
  line-height: 1.5;
  color: #212529;
  background-color: #fff;

  width: 25%;

  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:hover {
    color: #212529;
    background-color: #e9ecef;
    border-color: #cad1d7;
  }

  & .dropdown-toggle {
    border: none;
    background-color: white;
    color: rgb(13, 149, 34);
    font-size: 12px;
  }

  & .priority-btn:hover {
    border: none;
    background-color: white;
    color: rgb(13, 149, 34);
    font-size: 12px;
  }
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 12px;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
`;

export const SubTaskInfoTable = styled(Table)`
  th {
    /* Add custom styles for table headers here */
    font-size: 12px;
    /* padding-left: 1em; */
    width: 100px;

    /* &:nth-child(2) {
      width: 150px;
    } */
  }

  td {
    /* Add custom styles for table cells here */
    font-size: 12px;
    width: 70px;

    /* input {
      width: 40px;
    } */
  }

  tbody tr td:nth-child(3) input {
    /* Add custom styles for odd table rows here */
    width: 60px;
    font-size: 10px;
  }

  tbody tr td:nth-child(4) input {
    /* Add custom styles for odd table rows here */
    width: 60px;
    font-size: 10px;
  }
`;

export const SubTaskHourInfo = styled(Row)`
  display: table;

  input {
    display: table-cell;
    width: 40px;
    font-size: 10px;
  }
  span {
    display: table-cell;
    width: 1px;
    left: 0;
  }
`;

export const SubTaskCardStyle = styled(Container)`
  cursor: pointer;
  font-size: 12px;
  margin-top: 1rem;
`;

export const SubTaskCardStyleRow = styled(Row)`
  display: flex;

  & > div {
    flex: 1;
    padding-bottom: 0.3rem;
    text-align: center;
  }

  &:nth-child(1) {
    font-size: 15px;
    text-align: center;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    color: #000; /* Default color for the row */

    ${(props) =>
      props.priority === 'urgent' &&
      css`
        &:first-child {
          color: #db0f27;
        }
      `}

    ${(props) =>
      props.priority === 'high' &&
      css`
        &:first-child {
          color: #fc6a03;
        }
      `}

    ${(props) =>
      props.priority === 'medium' &&
      css`
        &:first-child {
          color: #e6cc00;
        }
      `}
      ${(props) =>
      props.priority === 'low' &&
      css`
        &:first-child {
          color: green;
        }
      `}
  }
`;
